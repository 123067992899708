// Handle inputs events
export function handleInputs() {
  const validate = (value, container) => {
    if (value) {
      container.classList.add('not-empty');
      return;
    }
    container.classList.remove('not-empty');
  };

  const setActive = (isActive, container) => {
    if (isActive) {
      container.classList.add('active');
      return;
    }
    container.classList.remove('active');
  };

  // In case there's more than one element active, setActive(false) for all
  // Happens mostly when data gets autocompleted in chrome
  const clearAllActive = (inputContainers) =>
    inputContainers.forEach(
      (container) =>
        container.classList.contains('active') && setActive(false, container)
    );

  const inputContainers = [
    ...document.querySelectorAll('.contact__form .wpforms-field'),
    ...document.querySelectorAll('.get_a_quote .wpforms-field'),
    ...document.querySelectorAll('.get_free_consultation .wpforms-field'),
    ...document.querySelectorAll('.single_service .wpforms-field'),
    ...document.querySelectorAll('.contact_form .wpforms-field'),
    ...document.querySelectorAll('.pricing_form .wpforms-field'),
  ];

  if (inputContainers.length) {
    inputContainers.forEach((container) => {
      const childsArray = Array.from(container.children);

      const input = childsArray.filter(
        (element) =>
          element.tagName === 'INPUT' || element.tagName === 'TEXTAREA'
      )[0];

      if (input) {
        validate(input.value, container);

        const mut = new MutationObserver(function (mutations) {
          const errorInputNumber = container.getAttribute('data-field-id');
          const parentFormElement = mutations[0].target.closest('form');

          if (mutations[0].target.classList.contains('wpforms-error')) {
            parentFormElement.classList.add(
              `wpforms-error-${errorInputNumber}`
            );

            return;
          }

          parentFormElement.classList.remove(
            `wpforms-error-${errorInputNumber}`
          );
        });

        mut.observe(input, {
          attributes: true,
        });

        if (input.hasAttribute('data-inputmask-mask')) {
          input.addEventListener('keyup', function (e) {
            validate(e.target.value, container);
          });
        }

        input.addEventListener('input', function (e) {
          validate(e.target.value, container);
        });

        input.addEventListener('paste', function (e) {
          validate(e.target.value, container);
        });

        input.addEventListener('focus', function () {
          setActive(true, container);
        });

        input.addEventListener('blur', function () {
          clearAllActive(inputContainers);
        });

        input.addEventListener('mouseover', function (e) {
          e.target.setAttribute('placeholder', '');
        });

        // Set max-length of email inputs to 255 - wpforms doesnt provide such feature for email inputs
        if (input.getAttribute('type') === 'email')
          input.setAttribute('maxlength', 255);
      }
    });
  }

  const formWrapper = document.querySelector('.contact__form_wrapper');

  if (formWrapper) {
    const contactForm = formWrapper.querySelector('form.wpforms-form');

    if (!contactForm) {
      formWrapper.classList.add('no-form');
      handleConfirmationView();
    }
  }
}

// show form error box
export function handleContactFormSendingError(submitEl, errorBoxTemplate) {
  const errorBoxNode = errorBoxTemplate.content.cloneNode(true);
  const errorBoxElButton = errorBoxNode.querySelector('button');
  const parentFormElement = submitEl.closest('form');
  const errorMessageTime = 3000;
  let errorBoxTimeoutId;

  const handleErrorBoxVisibility = () => {
    parentFormElement.classList.forEach((className) => {
      if (className.includes('wpforms-error-')) {
        clearTimeout(errorBoxTimeoutId);
        submitEl.classList.add('hidden');
        const errorBoxEl = submitEl.parentElement.querySelector(
          '.contact__error_box'
        );

        errorBoxEl.classList.add('visible');
        setTimeout(() => errorBoxEl.classList.add('fade'), 10);
        errorBoxTimeoutId = setTimeout(() => {
          if (errorBoxEl.classList.contains('fade')) {
            errorBoxEl.classList.remove('fade');
          }

          if (submitEl.classList.contains('hidden')) {
            submitEl.classList.remove('hidden');
          }
        }, errorMessageTime);
      }
    });
  };

  submitEl.parentElement.append(errorBoxNode);

  submitEl.addEventListener('click', function () {
    setTimeout(handleErrorBoxVisibility, 0);
  });

  errorBoxElButton.addEventListener('click', function (e) {
    setTimeout(() => e.target.parentElement.classList.remove('visible'), 250);
    e.target.parentElement.classList.remove('fade');
    if (submitEl.classList.contains('hidden'))
      submitEl.classList.remove('hidden');
  });
}

// render confirmation view
export function handleConfirmationView() {
  const confirmationDiv = document.querySelector(
    '.wpforms-confirmation-container-full'
  );
  const confirmationTemplate = document.querySelector(
    '#confirmation-message-box'
  );
  const newConfirmationContent = confirmationTemplate.content.cloneNode(true);

  confirmationDiv.innerHTML = '';
  confirmationDiv.append(newConfirmationContent);
}
