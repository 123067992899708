import $ from 'jquery';
import handleModal from './handleModal';

$(() => {
  // Get a quote form modal
  if ($('#get_a_quote_modal')) {
    handleModal(
      '.get_quote_button',
      '#get_a_quote_modal_close',
      '#get_a_quote_modal'
    );
  }

  if ($('#get_free_consultation_modal')) {
    handleModal(
      '.get_free_consultation_button',
      '#get_free_consultation_modal_close',
      '#get_free_consultation_modal'
    );
  }

  if ($('#job_offer_form_modal')) {
    handleModal(
      '.job_offer_form_modal_open',
      '#job_offer_form_modal_close',
      '#job_offer_form_modal'
    );
  }
});
