export default function checkCaptcha() {
  const formsWithCaptchasAndFileUploads = ['828', '833'];

  // Get basic form information
  let fileUploadFieldId = 0;
  let formId = 0;

  let form = document.querySelectorAll('form[id^="wpforms-form-"]');

  form.forEach((iteratedForm) => {
    const tempFormId = iteratedForm
      .getAttribute('id')
      .replace('wpforms-form-', '');

    if (formsWithCaptchasAndFileUploads.includes(tempFormId)) {
      formId = tempFormId;
      form = iteratedForm;
    }
  });

  if (!formId) {
    return null;
  }

  const fileUploadField = form.querySelector('.wpforms-field-file-upload');

  if (fileUploadField) {
    fileUploadFieldId = fileUploadField.getAttribute('data-field-id');
  }

  const formContainer = document.getElementById(`wpforms-form-${formId}`);

  const config = { childList: true, subtree: true };
  const mutationObserverCallback = (mutationList, observer) => {
    let hasCaptchaContainerBeenAdded = false;

    mutationList.forEach((mutation) => {
      if (mutation.addedNodes.length > 0) {
        if (mutation.addedNodes[0].id === 'wpforms-field_recaptcha-error') {
          hasCaptchaContainerBeenAdded = true;
        }
      }
    });

    if (hasCaptchaContainerBeenAdded) {
      // Remove file preview when captcha fails
      const fileInputContainer = document.querySelector(
        `div[data-input-name=wpforms_${formId}_${fileUploadFieldId}]`
      );

      if (fileInputContainer) {
        const removeContainer = fileInputContainer.querySelectorAll(
          '.dz-preview > .dz-remove'
        );

        if (removeContainer.length > 0) {
          removeContainer.forEach((item) => item.click());
        }
      }

      // Add notification when captcha fails
      const fileUploadContainer = document.getElementById(
        `wpforms-${formId}-field_${fileUploadFieldId}-container`
      );

      const url = window.location.href;
      const isPL = url.includes('/pl/');

      if (fileUploadContainer) {
        const currentError = document.getElementById(
          `wpforms-${formId}-field_${fileUploadFieldId}-error`
        );

        if (!currentError) {
          const newError = document.createElement('label');
          newError.setAttribute(
            'for',
            `wpforms-${formId}-field_${fileUploadFieldId}`
          );
          newError.classList.add('wpforms-error');
          newError.id = `wpforms-${formId}-field_${fileUploadFieldId}-error`;
          newError.innerHTML = isPL
            ? 'Plik został usunięty z powodu błędu Google reCAPTCHA. Proszę dodać plik ponownie.'
            : 'File was removed due to Google reCAPTCHA error. Please add the file again.';

          fileUploadContainer.append(newError);
        } else {
          currentError.innerHTML = isPL
            ? 'Plik został usunięty z powodu błędu Google reCAPTCHA. Proszę dodać plik ponownie.'
            : 'File was removed due to Google reCAPTCHA error. Please add the file again.';

          const uploadButton = formContainer.querySelector('.dz-clickable');

          if (uploadButton) {
            uploadButton.addEventListener('click', () => {
              currentError.remove();
            });
          }
        }
      }

      observer.disconnect();
    }
  };

  // Set mutation observer for recaptcha error when submit button is clicked
  const submitButton = document.getElementById(`wpforms-submit-${formId}`);

  submitButton.addEventListener('click', () => {
    const observer = new MutationObserver(mutationObserverCallback);
    observer.observe(formContainer, config);
  });

  return formId;
}
